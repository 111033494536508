export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const alphaOrEmailRule = {
  getMessage(field) {
    return `The ${field} must be either a valid alphabetic or e-mail`
  },
  validate(value) {
    /* eslint-disable no-useless-escape */
    const regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    /* eslint-enable no-useless-escape */

    /* eslint-disable no-useless-escape */
    const regexAlpha = /^[a-zA-Z0-9]+$/i
    /* eslint-enable no-useless-escape */
    // Check for either of these to return true
    return regexEmail.test(value) || regexAlpha.test(value)
  },
}

export const validatorCountryCode = {
  message(field) {
    return `The ${field} must include a country code of +1 or +52`
  },
  validate(value) {
    return value.startsWith('+1') || value.startsWith('+52')
  },
}

export const validatorPhoneNumber = {
  message(field) {
    return `The ${field} must contain 10 digits excluding country code`
  },
  validate(value) {
    const phone = value.replace('+1', '').replace('+52', '').replaceAll(' ', '')
    return phone.length === 10
  },
}
